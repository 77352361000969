import classNames from "classnames/bind";
import styles from "./PViAreaTab.module.scss";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";

const cx = classNames.bind(styles);

export const PViAreaTab = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};
   if (!p) return null;

   const { housing_authority_id } = p || {};

   const db_HA = dbcb("sos_housing_authorities", [{ column: "haid", id: housing_authority_id }], () =>
      qPVi_Property.refetch()
   );
   const db_N = dbcb("sos_neighborhoods", [{ column: "nid", id: housing_authority_id }], () => qPVi_Property.refetch());
   return (
      <div className={cx("container")}>
         <div className={cx("topC")}>
            <SoSDataPoint title="County Name" value={p.county_name} type="bold-title" />
            <SoSDataPoint title="ZIP Code" value={p.zip} type="bold-title" />
            <SoSDataPoint title="Market" value={p.market_name} type="bold-title" />
            <SoSDataPoint title="Neighborhood" value={p.neighborhood_name} type="bold-title" database={db_N("name")} />
            <SoSDataPoint
               title="Housing Authorities"
               value={p.housing_authority_name}
               type="bold-title"
               database={db_HA("name")}
            />
         </div>
         <div className={cx("marketC")}>
            <span className={cx("heading")}>Market</span>
            <div className={cx("infoTextC")}>
               <SoSDataPoint title="Name" value={p.market_name} type="bold-title" />
               <SoSDataPoint title="Property Manager" value={p.property_manager_name} type="bold-title" />
               <SoSDataPoint title="General Contractor" value="Name" type="bold-title" isMocked />
            </div>
            <div className={cx("infoNumberC")}>
               <SoSDataPoint
                  title="Avg. Market Reno Costs"
                  format="currency"
                  value={55183}
                  type="bold-value"
                  isMocked
                  isHistory
               />
               <SoSDataPoint
                  title="Average Purchase Price"
                  format="currency"
                  value={55183}
                  type="bold-value"
                  isMocked
                  isHistory
               />
               <SoSDataPoint title="Average ARV" format="currency" value={55183} type="bold-value" isMocked isHistory />
            </div>
         </div>
         <div className={cx("housingAuthorityC")}>
            <span className={cx("heading")}>Housing Authorities</span>
            <div className={cx("infoC")}>
               <SoSDataPoint title="Type" value={p.housing_authority_name} type="bold-title" />
               <SoSDataPoint
                  title="Payment Standard"
                  value={p.housing_authority_payment_standard_type}
                  type="bold-title"
               />
            </div>
         </div>
         <div className={cx("neighborhoodC")}>
            <span className={cx("heading")}>Neighborhood</span>
            <div className={cx("infoTextC")}>
               <SoSDataPoint title="Neighborhood" value={p.neighborhood_name} type="bold-title" />
            </div>
            <div className={cx("infoNumberC")}>
               <SoSDataPoint
                  title="Average Purchase Price"
                  format="currency"
                  value={55183}
                  type="bold-value"
                  isMocked
                  isHistory
               />
               <SoSDataPoint
                  title="Average Renovation"
                  format="currency"
                  value={55183}
                  type="bold-value"
                  isMocked
                  isHistory
               />
               <SoSDataPoint title="Average ARV" format="currency" value={55183} type="bold-value" isMocked isHistory />
            </div>
            <div className={cx("infoNumberC")}>
               <SoSDataPoint
                  title="Average Vacancy Rate"
                  format="percentage"
                  value={55.1 / 100}
                  type="bold-value"
                  isMocked
                  isHistory
               />
               <SoSDataPoint
                  title="Average Annual R&M Costs"
                  format="currency"
                  value={55183}
                  isMocked
                  isHistory
                  type="bold-value"
               />
               <SoSDataPoint
                  title="Applicable Leasing agencies"
                  format="currency"
                  value={55183}
                  isMocked
                  isHistory
                  type="bold-value"
               />
            </div>
         </div>
      </div>
   );
};
