import classNames from "classnames/bind";
import styles from "./OffersPage.module.scss";
import { OffersViableProperties } from "./OffersViableProperties/OffersViableProperties";
import { OffersLeadsList } from "./OffersLeadsList/OffersLeadsList";
import { OffersProvider } from "../../contexts/OffersContext";
import { OffersLeadDetail } from "./OffersLeadDetail/OffersLeadDetail";
import { PViProvider } from "../../contexts/PViContext";
import { PViPropertyDetailsModal } from "../PropertyViabilityPage/PViPropertyDetailsModal/PViPropertyDetailsModal";
import ReactJoyride, { Step } from "react-joyride";

const cx = classNames.bind(styles);

const Offers = () => {
   return (
      <div className={cx("contentC")}>
         <ReactJoyride continuous showSkipButton showProgress steps={joyRideSteps} />
         <PViPropertyDetailsModal />
         <OffersViableProperties />
         <div className={cx("bodyC")}>
            <OffersLeadsList />
            <OffersLeadDetail />
         </div>
      </div>
   );
};

export const OffersPage = () => (
   <OffersProvider>
      <PViProvider>
         <Offers />
      </PViProvider>
   </OffersProvider>
);

const joyRideSteps: Step[] = [
   {
      target: "._jr_offersViableProperties",
      content: "This shows the properties that are viable and ready to be turned into Leads!",
   },
   {
      target: "._jr_offersViablePropertiesExpand",
      content: "Click here to expand the list of viable properties.",
   },
   {
      target: "._jr_offersDefaultTermsConfig",
      content: "This is the default terms configuration for the offers. This terms will be copied for all new leads.",
   },
   {
      target: "._jr_offersLeadsFilters",
      content: "You can apply filters to leads here.",
   },
   {
      target: "._jr_offersLeadsList",
      content: "This shows the list of leads matched the current filters.",
   },
   {
      target: "._jr_offersLeadDetail",
      content: "This shows the details of the selected lead.",
   },
];
