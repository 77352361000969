import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { usePViContext } from "../../../contexts/PViContext";

export const useQPVi_Property = () => {
   const { sosAxios } = useSosAxios();

   const {
      pviState: { pid },
   } = usePViContext();

   return useQuery({
      enabled: !!pid,
      queryKey: [`useQPVi_Property`, pid],
      queryFn: () =>
         sosAxios.get(`/property-viability/properties/${pid}`).then<PVi_GetPropertyById_Response>(({ data }) => data),
   });
};

export class PVi_GetPropertyById_Source {
   sid!: number;
   created_at!: string;
   source!: string;
}

export class PVi_GetPropertyById_Property {
   pid!: string;
   sid!: number;
   price!: number;
   data_verification!: Record<string, string | undefined>;
   city!: string;
   zip!: string;
   address_one_line!: string;
   attom_avm!: string;
   attom_arv!: string;
   cap_rate!: number;
   sqft!: number;
   property_type!: string;
   state!: string;
   status!: string;
   market_name!: string;
   neighborhood_name!: string;
   county_name!: string;
   source_source!: string;
   renovation_cost!: number;
   street_view_url_400!: string;
   lat!: number;
   lng!: number;
   arv_uplift!: number;
   year_built!: number;
   property_manager_name!: string;
   property_manager_id!: number;
   source!: PVi_GetPropertyById_Source;
   asking_price!: number;
   target_purchase_price!: number;
   target_purchase_price_override!: number;
   housing_authority_id!: number;
   housing_authority_name!: string;
   housing_authority_payment_standard_type!: string;
   characteristics_water_supply!: string;
   characteristics_stories!: string;
   characteristics_roof_age!: string;
   characteristics_roof_type!: string;
   characteristics_siding!: string;
   characteristics_foundation!: string;
   characteristics_construction!: string;
   characteristics_drain_type!: string;
   characteristics_sewage_type!: string;
   feature_has_driveway!: boolean;
   feature_has_sidewalk!: boolean;
   feature_has_porch!: boolean;
   feature_has_pool!: boolean;
   feature_has_exterior_stairs!: boolean;
   feature_has_gate!: boolean;
   feature_has_garage!: boolean;
   feature_has_common_laundry!: boolean;
   feature_has_basement!: boolean;
   feature_has_elevator!: boolean;
   feature_has_yard!: boolean;
   feature_has_common_space!: boolean;
   feature_has_patio!: boolean;
   feature_has_deck!: boolean;
   feature_has_attic!: boolean;
   target_purchase_price_final!: number;
   max_price_arv_uplift!: number;
   max_price_cap_rate!: number;
   all_in!: number;
   simply_acquisition_fee_final!: number;
   title_insurance_final!: number;
   transfer_tax_final!: number;
   core_closing_cost!: number;
   estimated_acquisition_cost!: number;
   pm_reserve_total!: number;
   pm_onboarding_total!: number;
   payment_standard_type!: number;
   gross_rent_final!: number;
   noi_monthly!: number;
   noi_annually!: number;
   total_owner_reserves!: number;
   pgcalc_max_prices!: boolean;
}
export class PVi_GetPropertyById_Unit {
   uid!: number;
   bed!: number;
   bath!: number;
   gross_rent_final!: number;
   utilities_tenant_paying_final!: number;
   hcv_rent_final!: number;
   hcv_rent!: number;
   hcv_source!: string;
}

export class PVi_GetPropertyById_Response {
   property!: PVi_GetPropertyById_Property;
   units!: PVi_GetPropertyById_Unit[];
}
