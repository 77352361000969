import classNames from "classnames/bind";
import styles from "./OffersFilterByPeopleModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { TSoSInputSelect_option } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrSosButton } from "@simplyhomes/react";
import { useQ_Users } from "../../../hooks/queries/user/useQ_Users";
import SoSFilter from "../../../components/common/SoSFilter/SoSFilter";
import { useQContacts_getContacts } from "../../../hooks/contact/queries/useQContacts_getContacts";
import { useState } from "react";
import { useOffersQueryParams } from "../../../hooks/offers/hooks/useOffersQueryParams";

const cx = classNames.bind(styles);

export const OffersFilterByPeopleModal = ({ onClose }: OffersFilterByPeopleModalProps) => {
   const {
      assignee,
      cids,
      setAssigneeIds: setQueryAssigneeIds,
      setContactIds: setQueryContactIds,
   } = useOffersQueryParams();

   const [assigneeIds, setAssigneeIds] = useState<string[]>(assignee);

   const [contactIds, setContactIds] = useState<string[]>(cids);

   const qUsers = useQ_Users();

   const qGetAllContact = useQContacts_getContacts();

   const userAssignOptions: TSoSInputSelect_option[] =
      qUsers.data?.users.map((user) => ({
         label: user.name,
         value: user.uid,
         sub: user.email,
      })) || [];

   const contactOptions: TSoSInputSelect_option[] =
      qGetAllContact.data?.contacts.map((contact) => ({
         label: contact.name,
         value: contact.cid.toString(),
         sub: contact.email,
      })) || [];

   const assignNames = assigneeIds.map((id) => {
      const user = qUsers.data?.users.find((user) => user.uid === id);
      return user ? user.name : "";
   });

   const contactNames = contactIds.map((id) => {
      const contact = qGetAllContact.data?.contacts.find((contact) => contact.cid.toString() === id);
      return contact ? contact.name : "";
   });

   const handleApplyFilter = () => {
      setQueryAssigneeIds(assigneeIds.map((a) => a.toString()));
      setQueryContactIds(contactIds);
      onClose();
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/person_search_black.svg")} alt="" />
            </div>
            <span>Filter by people</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("bodyC")}>
            <span>Assignee</span>
            <div className={cx("inputValueC")}>
               <SoSFilter
                  type="select"
                  title="Select Assignee"
                  options={userAssignOptions}
                  value={assigneeIds}
                  onChange={(op) => setAssigneeIds(op.map((o) => o.value))}
                  isMulti={true}
                  hasSubValue
               />
            </div>
            <div className={cx("selectPeople")}>
               {assignNames.map((assignee, i) => (
                  <div className={cx("action")} key={i}>
                     <span>{assignee}</span>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <div className={cx("bodyC")}>
            <span>Contact</span>
            <div className={cx("inputValueC")}>
               <SoSFilter
                  type="select"
                  title="Select Contact"
                  options={contactOptions}
                  value={contactIds}
                  onChange={(op) => setContactIds(op.map((o) => o.value))}
                  isMulti={true}
                  hasSubValue
               />
            </div>
            <div className={cx("selectPeople")}>
               {contactNames.map((contact, i) => {
                  return (
                     <div className={cx("action")} key={i}>
                        <span>{contact}</span>
                     </div>
                  );
               })}
            </div>
         </div>

         <div className={cx("btnSave")}>
            <SHrSosButton
               buttonProps={{
                  className: cx("btn"),
                  onClick: () => handleApplyFilter(),
               }}
            >
               Apply Filter
            </SHrSosButton>
         </div>
      </div>
   );
};

type OffersFilterByPeopleModalProps = {
   onClose: () => void;
};
