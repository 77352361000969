import classNames from "classnames/bind";
import styles from "./OffersSource.module.scss";
import { SoSDataPoint } from "../../../../components/SoSDataPoint/SoSDataPoint";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useQOffers_GetPropertyByIdInLead } from "../../../../hooks/offers/queries/leads/useQOffers_GetPropertyByIdInLead";
import { useState } from "react";
import { OffersCreateNewContactModal } from "../../OffersCreateNewContactModal/OffersCreateNewContactModal";

const cx = classNames.bind(styles);

export const OffersSource = () => {
   const qOfferGetPropertyByIdInLead = useQOffers_GetPropertyByIdInLead();
   const property = qOfferGetPropertyByIdInLead.data?.property;
   const source = qOfferGetPropertyByIdInLead.data?.source;
   const [isOpenAddContactModal, setIsOpenAddContactModal] = useState(false);

   return (
      <div className={cx("container")}>
         <div className={cx("sourcesC")}>
            <span>Source</span>
            {qOfferGetPropertyByIdInLead.isLoading ? (
               <div className={cx("loading")}>
                  <SHrSpinner />
               </div>
            ) : qOfferGetPropertyByIdInLead.data?.source.contacts.length === 0 ? (
               <div className={cx("noContact")}>No source's contact found</div>
            ) : (
               source?.contacts.map((contact, i) => (
                  <div className={cx("sourceC")} key={i}>
                     <SoSDataPoint title="Seller Name" type="bold-title" value={contact.name || " Kelly Finland"} />
                     <SoSDataPoint
                        title="Seller Phone"
                        type="bold-title"
                        value={contact.phone_primary || "(000) 000 0000"}
                     />
                     <SoSDataPoint
                        title="Seller Email"
                        type="bold-title"
                        value={contact.email || "kelly.f@gmail.com"}
                     />
                     <SoSDataPoint title="Date Created" type="bold-title" value="12 May 2023" />
                     <SoSDataPoint title="Source Type" type="bold-title" value={source.source} />
                  </div>
               ))
            )}
         </div>
         <div className={cx("additionContactC")}>
            <div className={cx("titleC")}>
               <span>Additional Contacts</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setIsOpenAddContactModal(true),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  New contact
               </SHrSosButton>
            </div>
            {qOfferGetPropertyByIdInLead.isLoading ? (
               <div className={cx("loading")}>
                  <SHrSpinner />
               </div>
            ) : qOfferGetPropertyByIdInLead.data?.source.contacts.length === 0 ? (
               <div className={cx("noContact")}>No property's contact found</div>
            ) : (
               property?.contacts.map((contact, i) => (
                  <div className={cx("sourceC")} key={i}>
                     <SoSDataPoint title="Name" type="bold-title" value={contact.name || "Joshua Grande, Realtor"} />
                     <SoSDataPoint title="Phone" type="bold-title" value={contact.phone_primary || "(000) 000 0000"} />
                     <SoSDataPoint title="Email" type="bold-title" value={contact.email || "kelly.f@gmail.com"} />
                  </div>
               ))
            )}
         </div>
         <SHrPopup show={isOpenAddContactModal}>
            <OffersCreateNewContactModal onClose={() => setIsOpenAddContactModal(false)} />
         </SHrPopup>
      </div>
   );
};
