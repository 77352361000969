import classNames from "classnames/bind";
import styles from "./PViUnitDetailsTab.module.scss";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";

const cx = classNames.bind(styles);

export const PViUnitDetailsTab = () => {
   const { property, units = [] } = useQPVi_Property().data || {};

   if (!property) return null;

   return (
      <div>
         {units.map((_, i) => (
            <div className={cx("unitDetailTabC")}>
               <PViUnitDetail unitIndex={i} key={i} />
            </div>
         ))}
         <div className={cx("addPhotosC")}>
            <SHrSosButton buttonProps={{ className: cx("btnAdd") }} type="outlined">
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
               </div>
               <span>Add Unit</span>
            </SHrSosButton>
         </div>
      </div>
   );
};

const PViUnitDetail = ({ unitIndex }: { unitIndex: number }) => {
   const qPVi_Property = useQPVi_Property();
   const { units = [], property: p } = qPVi_Property.data || {};

   if (!p) return null;
   const { housing_authority_id } = p || {};

   const unit = units[unitIndex];

   if (!unit) return null;

   const db_U = dbcb("sos_units", [{ column: "uid", id: unit.uid }], () => qPVi_Property.refetch());
   const db_HA = dbcb("sos_housing_authorities", [{ column: "haid", id: housing_authority_id }], () =>
      qPVi_Property.refetch()
   );

   return (
      <div className={cx("container")}>
         <div className={cx("heading-title")}>
            <span>Unit {unitIndex + 1}</span>
            <SoSConfirmWrapper type="default" onCancel={() => {}} onConfirm={() => {}}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
               </div>
            </SoSConfirmWrapper>
         </div>
         <div className={cx("unitDetailC")}>
            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Rooms & Stories</b>
                  </span>
               </div>
               <div className={cx("unitDataC")}>
                  <SoSDataPoint
                     title="Bedrooms"
                     type="bold-title"
                     value={unit.bed}
                     affix="beds"
                     icon="bedroom"
                     database={db_U("bed")}
                  />
                  <SoSDataPoint
                     title="Bathrooms"
                     type="bold-title"
                     value={unit.bath}
                     affix="baths"
                     icon="bathroom"
                     database={db_U("bath")}
                  />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Rent</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <SoSDataPoint title="HCV Rent" type="bold-title" value={unit.hcv_rent_final} format="currency" />
                  <SoSDataPoint
                     title="HCV Total Utility Allowance"
                     type="bold-title"
                     value={unit.utilities_tenant_paying_final}
                     format="currency"
                  />
                  <SoSDataPoint
                     title="HCV Estimated Gross Rent"
                     type="bold-title"
                     value={unit.gross_rent_final}
                     format="currency"
                  />
                  <SoSDataPoint title="HCV Source" type="bold-title" value={unit.hcv_source} database={db_HA("name")} />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Leasing</b>
                  </span>
               </div>

               <div className={cx("leasingC")}>
                  <div className={cx("leasingLeftC")}>
                     <SoSDataPoint title="Current Lease" type="bold-title" value={1500} isMocked format="currency" />
                     <SoSDataPoint
                        title="Current Lease Rent"
                        type="bold-title"
                        value={1500}
                        isMocked
                        format="currency"
                     />
                     <SoSDataPoint
                        title="Current Lease End"
                        type="bold-title"
                        value={1500}
                        isMocked
                        format="currency"
                     />
                     <SoSDataPoint
                        title="Current Lease Type"
                        type="bold-title"
                        value={1500}
                        isMocked
                        format="currency"
                     />
                  </div>

                  <div className={cx("leasingRightC")}>
                     <div className={cx("inspectionAsset")}>
                        <div className={cx("headingC")}>
                           <span>
                              <b>Initial SOW</b>
                           </span>
                        </div>

                        <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                           Upload a file
                        </SHrSosButton>

                        <div className={cx("linkC")}>
                           <div className={cx("linkUploadC")}>
                              <div className={cx("img-icon")}>
                                 <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
                              </div>
                              <span>File name.pdf</span>
                              <div className={cx("linkIconsC")}>
                                 <div className={cx("img-icon")}>
                                    <img
                                       src={CF_CDN_URL(
                                          "/assets/property_validator/drive_file_rename_outline_black.svg"
                                       )}
                                       alt=""
                                    />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
                                 </div>
                              </div>
                           </div>
                           <div className={cx("linkUploadC")}>
                              <div className={cx("img-icon")}>
                                 <img
                                    src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")}
                                    alt=""
                                 />
                              </div>
                              <span>File name.pdf</span>
                              <div className={cx("linkIconsC")}>
                                 <div className={cx("img-icon")}>
                                    <img
                                       src={CF_CDN_URL(
                                          "/assets/property_validator/drive_file_rename_outline_black.svg"
                                       )}
                                       alt=""
                                    />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                                 </div>
                                 <div className={cx("img-icon")}>
                                    <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Heating Source Type</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <SoSDataPoint title="Heating Source Type" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint
                     title="Water Heating Source Type"
                     type="bold-title"
                     value={1500}
                     isMocked
                     format="currency"
                  />
                  <SoSDataPoint title="Cooking Source Type" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>---?</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <SoSDataPoint title="Electric Type" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Plumbing Type" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Access Details</b>
                  </span>
               </div>

               <div className={cx("unitDataC")}>
                  <SoSDataPoint title="Object Type" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Code" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Owner" type="bold-title" value={1500} isMocked format="currency" />
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Refrigerator Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />

                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Stove Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>Microwave Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <div className={cx("unitDataDetailC")}>
               <div className={cx("unitDataHeadingC")}>
                  <div className={cx("dot")}></div>
                  <span>
                     <b>HVAC Details</b>
                  </span>
               </div>

               <div className={cx("unitDataRightC")}>
                  <SoSDataPoint title="Exists" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Serial value" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Model" type="bold-title" value={1500} isMocked format="currency" />
                  <SoSDataPoint title="Condition" type="bold-title" value={1500} isMocked format="currency" />
                  <div className={cx("uploadImg")}>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img")}>
                        <img src="" alt="" />
                     </div>
                     <div className={cx("img", "addImg")}>
                        <img src={CF_CDN_URL("/assets/property_validator/add_photo_alternate.svg")} alt="" />
                     </div>
                  </div>
               </div>
            </div>

            <hr className={cx("divide-hr")} />
         </div>
      </div>
   );
};
