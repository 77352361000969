import { useMDatabase_UpdateValue_Params } from "./useMDatabase_UpdateValue";

export const database = {
   sos_properties: [
      "data_verification",
      "renovation_cost",
      "sqft",
      "property_type",
      "year_built",
      "target_purchase_price_override",
      "characteristics_water_supply",
      "characteristics_sewage_type",
      "characteristics_drain_type",
      "characteristics_construction",
      "characteristics_foundation",
      "characteristics_siding",
      "characteristics_roof_type",
      "characteristics_roof_age",
      "characteristics_stories",
      "feature_has_attic",
      "feature_has_deck",
      "feature_has_patio",
      "feature_has_common_space",
      "feature_has_yard",
      "feature_has_elevator",
      "feature_has_basement",
      "feature_has_common_laundry",
      "feature_has_garage",
      "feature_has_gate",
      "feature_has_pool",
      "feature_has_porch",
      "feature_has_sidewalk",
      "feature_has_driveway",
      "feature_has_exterior_stairs",
      "pgcalc_max_prices",
   ],
   sos_sources: ["price", "source"],
   sos_units: ["bed", "bath"],
   sos_leads: ["next_actions", "status"],
   sos_neighborhoods: ["name"],
   sos_housing_authorities: ["name"],
   sos_rel_sources_properties: ["price"],
} as const;

export type Database_Table = keyof typeof database;
export type Database_Column = (typeof database)[Database_Table][number];

export const database_helpers_createPayloadHelper =
   (table: Database_Table, identifiers: useMDatabase_UpdateValue_Params["identifiers"], onComplete?: () => void) =>
   (column: (typeof database)[typeof table][number]): useMDatabase_UpdateValue_Params => ({
      table,
      column,
      identifiers,
      onComplete,
   });
