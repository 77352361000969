import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import styles from "./SoSInputTitle.module.scss";
export const SoSInputTitle = (p: TSoSInputTitleProps) => {
   const { handleValue, title, type, value, inputProps, placeholder, error, icon, labelProps, isNotChanged } = p;
   return (
      <label {...labelProps} className={`${labelProps?.className} ${styles[`style-${type}`]}`}>
         {title && isNotChanged ? <span>{title}</span> : <b>{title}</b>}
         {isNotChanged ? (
            <div>{value}</div>
         ) : (
            <div className={`${error && styles.error}`}>
               <input
                  {...inputProps}
                  placeholder={placeholder}
                  type={type}
                  value={value}
                  onChange={(e) => {
                     if (handleValue) handleValue(e.target.value);
                  }}
               />
               {icon && (
                  <div>
                     <img src={icons[icon]} alt="icon" />
                  </div>
               )}
            </div>
         )}

         {error && <span className={styles.error}>{error}</span>}
      </label>
   );
};
const icons = {
   search: CF_CDN_URL("/assets/search.svg"),
};
export type TSoSInputTitleProps = {
   type: "text";
   isNotChanged?: boolean;
   title?: string;
   value: string;
   handleValue?: (value: string) => void;
   icon?: keyof typeof icons;
   error?: string;
   placeholder?: string;
   inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
   labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
};
