import classNames from "classnames/bind";
import { useEffect, useMemo, useState } from "react";
import { SHrSosButton, SHrSosTabsBar } from "@simplyhomes/react";

import styles from "./PropertyViabilityPage.module.scss";
import SoSFilter from "../../components/common/SoSFilter/SoSFilter";

import { PViProvider } from "../../contexts/PViContext";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { useQPVi_FilterOptions } from "../../hooks/property-viability/queries/useQPVi_FilterOptions";
import { SoSInputSelect } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import { usePViQueryParams } from "../../hooks/property-viability/hooks/usePViQueryParams";
import { PViAskingPriceFilter } from "./PViAskingPriceFilter/PViAskingPriceFilter";
import { PViGridView } from "./PViGridView/PViGridView";
import { PViListView } from "./PViListView/PViListView";
import { PViMapView } from "./PViMapView/PViMapView";
import { PViPropertyDetailsModal } from "./PViPropertyDetailsModal/PViPropertyDetailsModal";
import { useQPVi_Properties } from "../../hooks/property-viability/queries/useQPVi_Properties";
import {
   PVi_GetPropertyStatuses_PropertyStatus,
   useQPVi_PropertyStatuses,
} from "../../hooks/property-viability/queries/useQPVi_PropertyStatuses";
import { useMPVi_QuickPickProperty } from "../../hooks/property-viability/mutates/useMPVi_QuickPickProperty";
import { ESoS_PropertyStatus } from "@simplyhomes/utils";
import useDebounce from "../../hooks/react/useDebounce";
import ReactJoyride, { Step } from "react-joyride";

const cx = classNames.bind(styles);

enum EViews {
   GridView = "Grid View",
   MapView = "Map View",
   ListView = "List View",
}
const viewIcons: Record<string, string | undefined> = {
   "Grid View": CF_CDN_URL("/assets/property_viability/grid_view_primary.svg"),
   "Map View": CF_CDN_URL("/assets/property_viability/map_view_primary.svg"),
   "List View": CF_CDN_URL("/assets/property_viability/list_view_primary.svg"),
};

const joyrideSteps: Step[] = [
   {
      target: "._jr_pviTopLeftFilter",
      content: "This is the filter section. You can filter properties by these options.",
   },
   {
      target: "._jr_pviTabs",
      content: "This is the status tab. You can filter properties by status.",
   },
   {
      target: "._jr_pviProperties",
      content: "This is the properties section. You can view properties based on your selected properties.",
   },
   {
      target: "._jr_pviViews",
      content: "This is the view section. You can switch between grid, list, and map view.",
   },
   {
      target: "._jr_pviQuickPick",
      content: "This is the quick pick button. You can start quick pick queue from here.",
   },
];

const PVi = () => {
   const { view } = usePViQueryParams();

   return (
      <div className={cx(`contentC`)}>
         <ReactJoyride steps={joyrideSteps} showProgress showSkipButton continuous />
         <PViPropertyDetailsModal />
         <PViTop />
         <div className={cx("body", "_jr_pviProperties")}>
            {view === EViews.GridView ? (
               <PViGridView />
            ) : view === EViews.ListView ? (
               <PViListView />
            ) : view === EViews.MapView ? (
               <PViMapView />
            ) : null}
         </div>
      </div>
   );
};

const PViTop = () => {
   const qpPVi = usePViQueryParams();

   const { status, propertyTypes, zips, markets, sources, q, filters, view } = qpPVi;

   const { setStatus, setPropertyTypes, setZips, setMarkets, setSources, setQ, setView } = qpPVi;

   const qPVi_Properties = useQPVi_Properties();

   const qPVi_PropertyStatuses = useQPVi_PropertyStatuses();

   const mPVi_QuickPickProperty = useMPVi_QuickPickProperty();

   const [preTabs, setPreTabs] = useState<PVi_GetPropertyStatuses_PropertyStatus[]>([]);

   const tabOptions = useMemo(() => {
      const statuses = qPVi_PropertyStatuses.data?.statuses || preTabs || [];
      return (
         statuses.map(({ status, count }) => ({
            label: `${status} (${count})`,
            value: status,
         })) || []
      );
   }, [preTabs, qPVi_PropertyStatuses.data?.statuses]);

   const filterOptions = useQPVi_FilterOptions().data;

   const [search, setSearch] = useState(q);

   const searchDebounced = useDebounce(search, 500);

   const newPropLength = qPVi_PropertyStatuses.data?.statusMap?.New || 0;

   const isFilter = filters.markets.length > 0 || filters.propertyTypes.length > 0 ? " With Filters" : "";

   const handleChangeTab = (v: string) => {
      qPVi_PropertyStatuses.refetch();
      qPVi_Properties.refetch();
      setStatus(v);
   };

   useEffect(() => {
      setQ(searchDebounced);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchDebounced]);

   useEffect(() => {
      if (!qPVi_PropertyStatuses.data) return;
      setPreTabs(qPVi_PropertyStatuses.data?.statuses as PVi_GetPropertyStatuses_PropertyStatus[]);
   }, [qPVi_PropertyStatuses.data]);

   return (
      <div className={cx("top")}>
         <div className={cx("topFilter")}>
            <section className={cx("topLeft", "_jr_pviTopLeftFilter")}>
               <div className={cx("input_search")}>
                  <SoSInputTitle
                     value={search}
                     type="text"
                     handleValue={setSearch}
                     icon="search"
                     placeholder="Search the address"
                  />
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("filterList")}>
                  <SoSFilter
                     title="Market"
                     type="select"
                     isMulti={true}
                     value={markets}
                     onChange={(vs) => setMarkets(vs.map((o) => o.value))}
                     options={filterOptions?.markets || []}
                     className={cx("filter-dropdown")}
                  />

                  <SoSFilter
                     title="ZIP"
                     type="select"
                     isMulti={true}
                     value={zips}
                     onChange={(vs) => setZips(vs.map((o) => o.value))}
                     options={filterOptions?.zips || []}
                     className={cx("filter-dropdown")}
                  />
                  <SoSFilter
                     title="Property Type"
                     type="select"
                     isMulti={true}
                     value={propertyTypes}
                     onChange={(vs) => setPropertyTypes(vs.map((o) => o.value))}
                     options={filterOptions?.propertyTypes || []}
                     className={cx("filter-dropdown")}
                  />

                  <SoSFilter
                     title="Source"
                     type="select"
                     isMulti={true}
                     value={sources}
                     onChange={(vs) => setSources(vs.map((o) => o.value))}
                     options={filterOptions?.sources || []}
                     className={cx("filter-dropdown")}
                  />

                  <PViAskingPriceFilter />
               </div>
            </section>
            <section className={cx("topRight")}>
               <div className={cx("view", "_jr_pviViews")}>
                  <div className={cx("img")}>{viewIcons[view] && <img src={viewIcons[view]} alt="" />}</div>
                  <SoSInputSelect
                     options={Object.values(EViews)}
                     value={view}
                     onChange={(op) => setView(op.value)}
                     isMulti={false}
                     className={cx("filter-dropdown")}
                  />
               </div>

               {newPropLength > 0 && status === ESoS_PropertyStatus.NEW && (
                  <div className={cx("quickPickBtn", "_jr_pviQuickPick")}>
                     <SHrSosButton
                        loading={mPVi_QuickPickProperty.isPending}
                        buttonProps={{ onClick: () => mPVi_QuickPickProperty.mutate() }}
                     >
                        Start Quick Pick
                        {isFilter} ({newPropLength})
                     </SHrSosButton>
                  </div>
               )}
            </section>
         </div>
         <div className={cx("topTab", "_jr_pviTabs")}>
            <SHrSosTabsBar tabs={tabOptions} value={status} onChange={handleChangeTab} />
         </div>
      </div>
   );
};

export const PropertyViabilityPage = () => {
   return (
      <PViProvider>
         <PVi />
      </PViProvider>
   );
};
