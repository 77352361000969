import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { Database_Column, Database_Table } from "./utils";
import { useToastContext } from "../../../contexts/ToastContext";

export const useMDatabase_UpdateValue = (p?: useMDatabase_UpdateValue_Params) => {
   const { table, column, identifiers, onComplete = () => {} } = p || {};
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   const { toastDispatch } = useToastContext();
   const mutationKey = [table, column, identifiers];

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => {
         onComplete(true);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Value Updated" } });
      },
      onError: () => {
         onComplete(false);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "error", message: "Invalid Value" } });
      },
      mutationFn: ({ value }: { value: string | number | boolean | (string | number)[] }) =>
         sosAxios.patch("/database/update-value", { table, column, identifiers, value }),
   });
   const isMutating = useIsMutating({ mutationKey });

   return { ...mutation, isMutating };
};

export type useMDatabase_UpdateValue_Params = {
   table: Database_Table;
   column: Database_Column;
   identifiers: { column: string; id: string | number }[];
   onComplete?: (isSuccess: boolean) => void;
};
