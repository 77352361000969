import classNames from "classnames/bind";
import styles from "./PViUnderwriting.module.scss";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";
import { useMDatabase_UpdateValue } from "../../../../../hooks/database/mutations/useMDatabase_UpdateValue";

const cx = classNames.bind(styles);

export const PViUnderwriting = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p, units = [] } = qPVi_Property.data || {};

   const pid_id = { column: "pid", id: p?.pid || "" };

   const db_P = dbcb("sos_properties", [pid_id], qPVi_Property.refetch);

   const mDb_P_dataVerification = useMDatabase_UpdateValue(db_P("pgcalc_max_prices"));

   if (!p) return null;

   const {
      target_purchase_price_final,
      arv_uplift,
      cap_rate,
      max_price_arv_uplift,
      max_price_cap_rate,
      pgcalc_max_prices,
      all_in,
      renovation_cost,
      simply_acquisition_fee_final,
      title_insurance_final,
      transfer_tax_final,
      core_closing_cost,
      estimated_acquisition_cost,
      total_owner_reserves,
      pm_reserve_total,
      pm_onboarding_total,
      housing_authority_name,
      payment_standard_type,
      gross_rent_final,
      noi_monthly,
      noi_annually,
   } = p || {};
   return (
      <div className={cx("container")}>
         <div className={cx("purchaseDetailsC")}>
            <span className={cx("heading")}>Purchase details</span>
            <div className={cx("infoC")}>
               <div className={cx("data")}>
                  <SoSDataPoint type={"bold-value"} value={"--"} title="Contract price" isHistory isMocked />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={target_purchase_price_final}
                     title="Target purchase price"
                     isHistory
                  />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="percentage"
                     value={arv_uplift / 100}
                     title="ARV uplift"
                     isHistory
                  />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="percentage"
                     value={cap_rate / 100}
                     additionalInfor={"(HCV rent)"}
                     title="Cap rate"
                     isHistory
                  />
               </div>

               <div className={cx("data")}>
                  <SoSDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={max_price_arv_uplift}
                     title="Max price"
                     additionalInfor="(ARV uplift)"
                     isHistory
                     buttons={[
                        {
                           title: "refresh",
                           onClick: () => mDb_P_dataVerification.mutate({ value: true }),
                           loading: !!mDb_P_dataVerification.isMutating,
                        },
                     ]}
                  />
                  <SoSDataPoint
                     type={"bold-value"}
                     additionalInfor="(Cap rate)"
                     format="currency"
                     value={max_price_cap_rate}
                  />
               </div>
            </div>
         </div>
         <div className={cx("costC")}>
            <span className={cx("heading")}>Fees & Costs</span>
            <div className={cx("infoC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={all_in}
                  title="All-in cost"
                  isHistory
                  isMocked
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={renovation_cost}
                  title="Estimated Total Renovation Cost"
                  isHistory
               />
            </div>
            <div className={cx("infoNoBgC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={simply_acquisition_fee_final}
                  title="Simply Acquisition Fee"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={title_insurance_final}
                  title="Title insurance"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={transfer_tax_final}
                  title="Transfer tax"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={core_closing_cost}
                  title="Core closing costs"
                  isHistory
               />
            </div>
            <div className={cx("infoC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={estimated_acquisition_cost}
                  title="Estimated Acquisition Costs"
                  isHistory
               />
            </div>
            <div className={cx("infoNoBgC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={renovation_cost}
                  title="Renovation amount"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={total_owner_reserves}
                  title="Owner Reserve amount"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={pm_reserve_total}
                  title="PM Reserve Amount"
                  isHistory
               />
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={pm_onboarding_total}
                  title="PM Onboarding amount"
                  isHistory
               />
            </div>
            <div className={cx("infoC")}>
               <SoSDataPoint
                  type={"bold-value"}
                  format="currency"
                  value={10538150}
                  title="Startup costs"
                  isHistory
                  isMocked
               />
            </div>
         </div>
         <div className={cx("housingAuthorityC")}>
            <div className={cx("infoC")}>
               <div className={cx("dataC")}>
                  <SoSDataPoint type="bold-title" value={housing_authority_name} title="Housing Authority" />
                  <SoSDataPoint type="bold-title" value={payment_standard_type} title="Total HCV Payment Standard" />
                  <SoSDataPoint type="bold-value" format="currency" value={1785} isMocked icon="local_activity" />
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("dataC")}>
                  <SoSDataPoint
                     type="bold-value"
                     format="currency"
                     value={435}
                     title="Total Utility Deduction"
                     isHistory
                     isMocked
                  />
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("dataC")}>
                  <SoSDataPoint
                     type="bold-value"
                     format="currency"
                     value={gross_rent_final}
                     additionalInfor="/month"
                     title="Estimated Gross Rent"
                     isHistory
                  />
                  <SoSDataPoint type="bold-value" format="currency" value={1350} additionalInfor="/year" isMocked />
               </div>

               <div className={cx("dataC", "bg")}>
                  <SoSDataPoint
                     type="bold-value"
                     format="currency"
                     value={noi_monthly}
                     additionalInfor="/month"
                     title="NOI"
                     isHistory
                  />
                  <SoSDataPoint type="bold-value" format="currency" value={noi_annually} additionalInfor="/year" />
               </div>
            </div>
            {units.map((_, i) => (
               <UnitInformation key={i} unitIndex={i} />
            ))}

            <div className={cx("tableExpensesC")}>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn", "viewHistory"),
                     onClick: () => {},
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_viability/history_info500.svg")} alt="" />
                  </div>
                  Reset table
               </SHrSosButton>
               <ExpensesTable />
            </div>
         </div>
      </div>
   );
};

const ExpensesTable = () => {
   return (
      <div className={cx("tableContainer")}>
         <table>
            <thead>
               <tr>
                  <th>Expenses</th>
                  <th>
                     Monthly <br />
                     <span>$ --</span>
                  </th>
                  <th>
                     Annually <br /> <span>$ --</span>
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>
                     Vacancy
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                     </div>
                  </td>
                  <td className={cx("fs-20")}>$ 810.00</td>
                  <td>
                     <SoSDataPoint type={"only-value"} value={810} format="currency" />
                  </td>
               </tr>
               <tr>
                  <td>
                     R&M costs
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                     </div>
                  </td>
                  <td className={cx("fs-20")}>$ 810.00</td>
                  <td>
                     <SoSDataPoint type={"only-value"} value={810} format="currency" />
                  </td>
               </tr>
               <tr>
                  <td>
                     Simply utility costs
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                     </div>
                  </td>
                  <td className={cx("fs-20")}>$ 810.00</td>
                  <td>
                     <SoSDataPoint type={"only-value"} value={810} format="currency" />
                  </td>
               </tr>
               <tr>
                  <td>
                     Property tax
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                     </div>
                  </td>
                  <td className={cx("fs-20")}>$ 810.00</td>
                  <td>
                     <SoSDataPoint type={"only-value"} value={810} format="currency" />
                  </td>
               </tr>
               <tr>
                  <td>
                     Property insurance
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                     </div>
                  </td>
                  <td className={cx("fs-20")}>$ 810.00</td>
                  <td>
                     <SoSDataPoint type={"only-value"} value={810} format="currency" />
                  </td>
               </tr>
               <tr>
                  <td>
                     Simply Homes asset management fee
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                     </div>
                  </td>
                  <td className={cx("fs-20")}>$ 810.00</td>
                  <td>
                     <SoSDataPoint type={"only-value"} value={810} format="currency" />
                  </td>
               </tr>
               <tr>
                  <td>
                     Property management fees
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                     </div>
                  </td>
                  <td className={cx("fs-20")}>$ 810.00</td>
                  <td>
                     <SoSDataPoint type={"only-value"} value={810} format="currency" />
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   );
};

type UnitInformationProps = {
   unitIndex: number;
};

const UnitInformation = ({ unitIndex }: UnitInformationProps) => {
   const qPVi_Property = useQPVi_Property();

   const { units = [] } = qPVi_Property.data || {};

   const u = units[unitIndex];

   if (!u) return null;

   const db_U = dbcb("sos_units", [{ column: "uid", id: u.uid }], () => qPVi_Property.refetch());
   return (
      <div className={cx("unitContainer")}>
         <div className={cx("optionC")}>
            <div className={cx("valuesC")}>
               <SoSDataPoint
                  type="bold-title"
                  affix="beds"
                  value={u.bed}
                  title={`Unit ${unitIndex + 1}`}
                  icon="bedroom"
                  database={db_U("bed")}
               />
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("valuesC")}>
               <SoSDataPoint type="bold-title" value={"SAFMR"} title="Payment Standard" isMocked />
               <SoSDataPoint type="bold-value" format="currency" value={892.5} icon="local_activity" isMocked />
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("valuesC")}>
               <SoSDataPoint type="bold-value" format="currency" value={80000} title="Utility Deduction" isMocked />
            </div>
         </div>
         <div className={cx("utilityC")}>
            <span>
               <b>Document</b>
            </span>
            <div className={cx("nameDocC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/insert_drive_file_info500.svg")} alt="" />
               </div>
               <span>Unit 1_Utility Allowance_2037 Sampson St, Blackridge, PA 15221, USA</span>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/download_neutral700.svg")} alt="" />
               </div>
            </div>
            <SHrSosButton type="text" buttonProps={{ className: cx("btn") }}>
               Edit Utilities Allowance
            </SHrSosButton>
         </div>
      </div>
   );
};
