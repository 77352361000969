import classNames from "classnames/bind";
import styles from "./OffersLeadDetailSetting.module.scss";
import { OffersLeadOverview } from "../OffersLeadOverview/OffersLeadOverview";
import { OffersProperties } from "../OffersProperties/OffersProperties";
import { OffersInspectionAndSource } from "../OffersInspectionAndSource/OffersInspectionAndSource";
import { OffersTerms } from "../OffersTerms/OffersTerms";
import { OffersOffersSection } from "../OffersOffersSection/OffersOffersSection";

const cx = classNames.bind(styles);

export const OffersLeadDetailSetting = () => {
   return (
      <div className={cx("rightBodyC")}>
         <div className={cx("rightBodyLeftC")}>
            <OffersLeadOverview />
            <OffersProperties />
            <OffersInspectionAndSource />
            <OffersTerms />
            <OffersOffersSection />
         </div>
         {/* <div className={cx("rightBodyRightC")}></div> */}
      </div>
   );
};
