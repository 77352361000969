import classNames from "classnames/bind";

import { usePViContext } from "../../../../contexts/PViContext";
import styles from "./PViPreviousNextProperty.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useMPVi_QuickPickProperty } from "../../../../hooks/property-viability/mutates/useMPVi_QuickPickProperty";

const cx = classNames.bind(styles);

export const PViPreviousNextProperty = () => {
   const { pviState, pviOverwrite } = usePViContext();

   const { excludedPropertyIds } = pviState;

   const mPViQuickPickProperty = useMPVi_QuickPickProperty();

   const handleNext = () => {
      mPViQuickPickProperty.mutate();
   };

   const handlePrevious = () => {
      const lastExcludedPropertyId = excludedPropertyIds[excludedPropertyIds.length - 1];
      if (!lastExcludedPropertyId) return;
      const newExcludedPropertyIds = [...excludedPropertyIds].toSpliced(-1, 1);
      pviOverwrite({ excludedPropertyIds: newExcludedPropertyIds, pid: lastExcludedPropertyId });
   };

   return (
      <div className={cx("turningPageC", "_jr_pviQueueNavigation")}>
         {excludedPropertyIds.length > 0 && (
            <>
               <button className={cx("previousNextC")} onClick={handlePrevious}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/keyboard_double_arrow_left_black.svg")} alt="" />
                  </div>
                  <span>Previous</span>
               </button>
               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>
            </>
         )}
         <button className={cx("previousNextC")} onClick={handleNext}>
            <span>Next</span>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_validator/keyboard_double_arrow_right_black.svg")} alt="" />
            </div>
         </button>
      </div>
   );
};
