import styles from "./DevJim.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputSelect, TSoSInputSelect_option } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { useState } from "react";
import { useM_UploadFiles } from "../../hooks/mutates/sos/useM_UploadFiles";
import { SoSDataPoint } from "../../components/SoSDataPoint/SoSDataPoint";
import { database_helpers_createPayloadHelper as genDbCb } from "../../hooks/database/mutations/utils";
export const DevJim = () => {
   return (
      <div className={`${styles.contentC}`}>
         <TestDataPoint />
         <div className={`${styles.hidden}`}>
            <TestFileUpload />
            <TestInputSelectTags />
            <TestConfirmWrapper />
         </div>
      </div>
   );
};

const TestDataPoint = () => {
   const [value, setValue] = useState(["option0", "option1", "option0", "option0", "option0"]);
   // const db = genDbCb("sos_properties", [{ column: "pid", id: "1" }], () => {});
   const db = genDbCb("sos_leads", [{ column: "lid", id: "80" }]);
   const options = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   return (
      <div>
         <SoSDataPoint
            options_isMulti
            options={options}
            title="Test"
            type="bold-title"
            value={value}
            database={db("next_actions")}
            buttons={[{ title: "Test", onClick: () => alert("Test"), loading: false }]}
         />
      </div>
   );
};

const TestFileUpload = () => {
   const [files, setFiles] = useState<FileList | null>(null);
   const mUploadFiles = useM_UploadFiles();
   const handleUpload = () => {
      if (!files) return;
      mUploadFiles.mutate(files);
   };
   return (
      <div>
         <input title="file-upload" type="file" onChange={(e) => setFiles(e.target.files)} />
         <div>{files?.length}</div>
         {/* <button title="upload" disabled={!!file?.length} type="button"> */}
         <button title="upload" onClick={handleUpload} type="button">
            Upload
         </button>
      </div>
   );
};

const TestInputSelectTags = () => {
   const [selected, setSelected] = useState<TSoSInputSelect_option[]>([]);
   const options: TSoSInputSelect_option[] = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   return (
      <div>
         <SoSInputSelect isMulti value={selected} onChange={setSelected} options={options} />
      </div>
   );
};

const TestConfirmWrapper = () => (
   <SoSConfirmWrapper type="default" onCancel={() => alert("Cancelled")} onConfirm={() => alert("CONFIRMED")}>
      <SHrSosButton type="dangerous" buttonProps={{ onClick: () => alert("DO NOT USE ONCLICK ON CHILDREN") }}>
         Delete
      </SHrSosButton>
   </SoSConfirmWrapper>
);
