import classNames from "classnames/bind";
import styles from "./SoSRoudedTabs.module.scss";
const cx = classNames.bind(styles);

type TSoSRoudedTabsProps = {
   tabs: string[];
   value: string;
   onChange: (value: string) => void;
};

export const SoSRoudedTabs = ({ tabs, value, onChange }: TSoSRoudedTabsProps) => {
   return (
      <div className={cx("tabsContainer")}>
         {tabs.map((tab, index) => (
            <div key={index} className={cx("tab", { active: value === tab })} onClick={() => onChange(tab)}>
               {tab}
            </div>
         ))}
      </div>
   );
};
