import classNames from "classnames/bind";
import styles from "./PViDataVerification.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSDataPoint, SoSDataPoint_Params } from "../../../../components/SoSDataPoint/SoSDataPoint";
import { SHrSpinner } from "@simplyhomes/react";
import { usePViDataVerification } from "../../../../hooks/property-viability/hooks/usePViDataVerification";
const cx = classNames.bind(styles);

const DataPoint = (p: Omit<SoSDataPoint_Params, "options_isMulti">) => (
   <div className={cx(`datapoint`)}>
      <SoSDataPoint options_isMulti={false} {...(p as any)} />
   </div>
);

export const PViDataVerification = () => {
   const { rules, isUpdatingVerification } = usePViDataVerification();
   return (
      <div className={cx("missingDataC", "_jr_pviDataVerification")}>
         <div className={cx("headingC")}>
            <span>Missing/Unverified Data</span>
            {!!isUpdatingVerification && <SHrSpinner />}
         </div>
         {rules
            .sort((a, b) => (a.verified ? 1 : b.verified ? -1 : 0))
            .map(({ id, checks, datapoint, title, verified, verify }) => (
               <div key={id} className={cx(`dataPointC`)}>
                  <div>
                     <span>{title}</span>
                     <div>
                        <DataPoint type="bold-only-value" {...datapoint} />
                        <button onClick={verify} type="button" className={cx({ verified })} title="Verify">
                           <img src={CF_CDN_URL("/assets/property_validator/verified_black.svg")} alt="" />
                        </button>
                     </div>
                  </div>
                  <ul>
                     {checks
                        .filter((c) => !c.pass)
                        .map((c) => (
                           <li key={c.description} className={cx("warningC")}>
                              <img src={CF_CDN_URL("/assets/property_validator/warning_black.svg")} alt="" />
                              <span>{c.description}</span>
                           </li>
                        ))}
                  </ul>
               </div>
            ))}
      </div>
   );
};
