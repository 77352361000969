import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

// Usage:
// const handleDefault = async (o: SoS_GetOptions_Option) => {
//    if (!currentValue) dispatch({ overwrite: { value: o.value } });
// }
// const options = useSoSOptions_Offers_Simply_Terms({ handleDefault });

export const useQSoS_Options = ({ handleDefault = () => {}, ...params }: TQSoS_Options_Props) => {
   const { sosAxios } = useSosAxios();
   const query = useQuery<SoS_GetOptions_Response>({
      queryKey: [`useQSoS_Options`, params],
      queryFn: () =>
         sosAxios.get(`/options`, { params }).then(({ data }: { data: SoS_GetOptions_Response }) => {
            const defaultOption = data.options.find((o) => o.is_default);
            if (defaultOption) handleDefault(defaultOption);
            return data;
         }),
   });

   return query;
};

type TQSoS_Options_Props = {
   moid: string;
   housing_authority_id?: string | number;
   handleDefault?: (o: SoS_GetOptions_Option) => void;
};

export type SoS_GetOptions_Option = { value: string; label: string; is_default?: boolean };

export type SoS_GetOptions_Response = { options: SoS_GetOptions_Option[] };

type WrapperParams = Omit<TQSoS_Options_Props, "moid">;
export const useSoSOptions_PVi_Source_Reject_Reasons = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_source_reject_reason" });
export const useSoSOptions_Offers_Simply_Terms = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_simply_term" });
export const useSoSOptions_Offers_Leads_Statuses = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_lead_status" });
export const useSoSOptions_Offers_OfferStatuses = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_offer_status" });
export const useSoSOptions_Offers_OfferTypes = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_offer_type" });
export const useSoSOptions_Properties_PropertyTypes = (
   p?: WrapperParams & { housing_authority_id: string | number | undefined }
) => useQSoS_Options({ ...p, moid: "#properties.property_type" });
export const useSoSOptions_Sources_Source = (p?: WrapperParams) => useQSoS_Options({ ...p, moid: "#sources.source" });
export const useSoSOptions_Offers_Lead_Next_Actions = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_lead_next_action" });
