import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useQPVi_Property } from "../queries/useQPVi_Property";
import { usePViContext } from "../../../contexts/PViContext";

export const useMPVi_Property_DeleteUnit = ({ uid }: { uid: number }) => {
   const { sosAxios } = useSosAxios();

   const { pviState } = usePViContext();

   const { pid } = pviState;

   const qPVi_Property = useQPVi_Property();

   const mutationKey = ["useMPVi_Property_DeleteUnit", pid, uid];

   const isMutating = useIsMutating({ mutationKey });

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => qPVi_Property.refetch(),
      mutationFn: () => sosAxios.delete(`/property-viability/properties/${pid}/units/${uid}`),
   });
   return { ...mutation, isMutating };
};
