import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useM_UploadFiles = () => {
   const { sosAxios } = useSosAxios();
   const mutationKey = ["useM_UploadFiles"];
   const mutationFn = async (fileList: FileList) => {
      if (!fileList) return;
      const formData = new FormData();
      Array.from(fileList).forEach((file) => formData.append("files", file));
      return await sosAxios
         .post("/files", formData, { headers: { "Content-Type": "multipart/form-data" } })
         .then(({ data }) => console.log({ data }));
   };
   const mutation = useMutation({ mutationKey, mutationFn });
   const isMutating = useIsMutating({ mutationKey });
   return { ...mutation, isMutating };
};
