import { TableColumn } from "react-data-table-component";
import classNames from "classnames/bind";
import styles from "./PViViewHistoryModal.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import DataTable from "react-data-table-component";
import {
   useQPVi_PropertyHistory,
   useQPVi_PropertyHistory_Audit,
} from "../../../../hooks/property-viability/queries/useQPVi_PropertyHistory";
import { SHrSmallSpinner, SHrSpinner } from "@simplyhomes/react";
import { SHu_js_timeSince } from "@simplyhomes/utils";

const cx = classNames.bind(styles);

// const historyData: THistory[] = [
//    {
//       person: "Adam Pettengill",
//       time_modified: "09:00 AM 23 Feb 2024",
//       tab: "Unit Details",
//       section: "Unit 1",
//       data_label: "Bedrooms",
//       current_value: "2 beds",
//       value_changed: "3 beds",
//    },
//    {
//       person: "Adam Pettengill",
//       time_modified: "03:17 PM 22 Feb 2024",
//       tab: "Property Details",
//       section: "Property Overview",
//       data_label: "Property Type",
//       current_value: "Duplex",
//       value_changed: "Single Family",
//    },
//    {
//       person: "Adam Pettengill",
//       time_modified: "01:38 PM 21 Feb 2024",
//       tab: "Overview",
//       section: "--",
//       data_label: "Asking Price",
//       current_value: "1,000,500",
//       value_changed: "100,000",
//    },
// ];
const handleShowDetailInformationClick = () => {};

export const PViViewHistoryModal = ({ toggle }: TPViViewHistoryModal) => {
   const qPropHistory = useQPVi_PropertyHistory();
   return (
      <div className={cx("container")}>
         <div className={cx("topC")}>
            <span>History</span>
            <div className={cx("close-btn")}>
               <button type="button" title="close" onClick={toggle}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         {qPropHistory.isLoading ? (
            <div className={`${styles.loading}`}>
               <SHrSpinner />
               <span>Loading history...</span>
            </div>
         ) : (
            <div className={cx("tableC")}>
               <DataTable
                  columns={allHistoryColumn}
                  data={qPropHistory.data?.audits!}
                  onRowClicked={handleShowDetailInformationClick}
               />
            </div>
         )}
      </div>
   );
};

type TPViViewHistoryModal = {
   toggle: () => void;
};

export const allHistoryColumn: TableColumn<useQPVi_PropertyHistory_Audit>[] = [
   {
      name: <strong>Time modified</strong>,
      selector: (i) => `${i.created_at.split("T")[0]}(${SHu_js_timeSince(new Date(i.created_at).getTime())})`,
   },
   { name: <strong>Person</strong>, selector: (i) => i.user_name || i.user_email },
   { name: <strong>Column</strong>, selector: (i) => i.column },
   {
      name: <strong>Old</strong>,
      selector: (i) => (typeof i.old_value !== "string" ? JSON.stringify(i.old_value) : i.old_value),
   },
   {
      name: <strong>New</strong>,
      selector: (i) => (typeof i.new_value !== "string" ? JSON.stringify(i.new_value) : i.new_value),
   },
   // {
   //    name: (
   //       <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
   //          <div style={{ display: "flex", alignItems: "center", width: "16px", height: "16px" }}>
   //             <img src={CF_CDN_URL("/assets/property_validator/icon_restore_negative_500.svg")} alt="" />
   //          </div>
   //          <span style={{ color: "#F2415A", fontSize: "14px" }}>Reset all Data</span>
   //       </div>
   //    ),
   //    cell: () => (
   //       <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
   //          <div style={{ display: "flex", alignItems: "center", width: "16px", height: "16px" }}>
   //             <img src={CF_CDN_URL("/assets/property_validator/icon_restore_info_500.svg")} alt="" />
   //          </div>
   //          <span style={{ color: "#295BFF", fontSize: "14px" }}>Undo</span>
   //       </div>
   //    ),
   // },
];
