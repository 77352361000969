import { useSosAxios } from "../../axios/useSosAxios";

export const useQFns_AD = () => {
   const { sosAxios } = useSosAxios();
   const qfns_AD = {
      q_getSubscribedApps: () =>
         sosAxios.get("/user/apps/subscribed").then<TgetSubscribedApps_Resp>(({ data }) => data),
      subscribeApp: ({ appId, subscribe }: { appId: string; subscribe: boolean }) =>
         sosAxios.post("/user/apps/subscribed", {}, { params: { appId, subscribe } }).catch((err) => err),
   };
   return { qfns_AD };
};
type TgetSubscribedApps_Resp = { appIds: string[] };
