import classNames from "classnames/bind";
import { useState } from "react";
import { SHrSosButton } from "@simplyhomes/react";

import styles from "./OffersCreateNewContactModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputSelect, TSoSInputSelect_option } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { useQContacts_getContacts } from "../../../hooks/contact/queries/useQContacts_getContacts";
import { useToastContext } from "../../../contexts/ToastContext";
import { useMContacts_CreateConstact } from "../../../hooks/contact/mutates/useMContacts_CreateConstact";

const cx = classNames.bind(styles);

export const OffersCreateNewContactModal = ({ onClose }: TOffersCreateNewContactModalProps) => {
   const { toastDispatch } = useToastContext();

   const [selectedContact, setSelectedContact] = useState("");

   const [isCreateMode, setIsCreateMode] = useState(false);

   const qGetAllContact = useQContacts_getContacts();

   const { mutate, isPending } = useMContacts_CreateConstact({
      onSuccess: () => {
         onClose();
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create new contact is successful!",
            },
         });
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create new contact is failed!",
            },
         });
      },
   });

   const [internalEmailInput, setInternalEmailInput] = useState("");
   
   if (!qGetAllContact.data?.contacts) return null;
   const contactOptions: TSoSInputSelect_option[] = qGetAllContact.data?.contacts?.map((c) => ({
      label: c.email,
      value: c.cid.toString(),
   }));

   const handleSetValueCreateNewContact = (value: string) => {
      setIsCreateMode(true);
      setSelectedContact(value);
   };

   const handleCreateNewContact = () => {
      mutate({ email: selectedContact });
   };
   const isValidEmail = internalEmailInput.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/personal_add_black.svg")} alt="" />
            </div>
            <span>{isCreateMode ? "Create a contact" : "Select a contact"}</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("bodyC")}>
            <div className={cx("inputValueC")}>
               <SoSInputSelect
                  title="Email"
                  options={contactOptions}
                  value={selectedContact}
                  onChange={(op) => setSelectedContact(op.label)}
                  isMulti={false}
                  allowCreate={isValidEmail ? { cb: ({ value }) => handleSetValueCreateNewContact(value) } : undefined}
                  customCreateMessage={"contact with email"}
                  onInputChange={(value) => setInternalEmailInput(value)}
               />
            </div>
            {isCreateMode && (
               <div className={cx("createModeC")}>
                  <div className={cx("inputValueC")}>
                     <SoSInputTitle
                        title="Contact Name"
                        placeholder="Enter full name"
                        type="text"
                        value={""}
                        handleValue={() => {}}
                     />
                  </div>
                  <div className={cx("inputValueC")}>
                     <SoSInputTitle
                        title="Phone Number"
                        placeholder="(000) 000 0000"
                        type="text"
                        value={""}
                        handleValue={() => {}}
                     />
                  </div>
               </div>
            )}
         </div>
         <SoSConfirmWrapper
            title={`Are you sure you want to ${isCreateMode ? "create" : "apply"} this contact?`}
            content="This action allows to apply this contact."
            type="default"
            onConfirm={() => isCreateMode && handleCreateNewContact()}
         >
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     disabled: !selectedContact,
                  }}
                  loading={isPending}
               >
                  {isCreateMode ? "Create contact" : "Select contact"}
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};
type TOffersCreateNewContactModalProps = {
   onClose: () => void;
};
