import classNames from "classnames/bind";
import styles from "./PVipropertyDetailsTab.module.scss";
import { PViAssetPoint } from "../../PViAssetPoint/PViAssetPoint";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";
import {
   useSoSOptions_Properties_PropertyTypes,
   useSoSOptions_Sources_Source,
} from "../../../../../hooks/queries/options/useQSoS_Options";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";

const cx = classNames.bind(styles);

export const PVipropertyDetailsTab = () => {
   return (
      <div className={cx("container")}>
         <PropertyOverview />

         <hr className={cx("divide-hr")} />

         <Characteristics />

         <hr className={cx("divide-hr")} />

         <FeaturesAndAmenities />
      </div>
   );
};

const PropertyOverview = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};

   const { housing_authority_id } = p || {};

   const qProps_PropertyTypes = useSoSOptions_Properties_PropertyTypes({ housing_authority_id });
   const { options: options_props_propertyTypes } = qProps_PropertyTypes?.data || {};

   const qSources_Source = useSoSOptions_Sources_Source();
   const { options: options_sources_source } = qSources_Source?.data || {};

   if (!p) return null;

   const db_P = dbcb("sos_properties", [{ column: "pid", id: p.pid }], () => qPVi_Property.refetch());
   const db_S = dbcb("sos_sources", [{ column: "sid", id: p.source.sid }], () => qPVi_Property.refetch());
   return (
      <div className={cx("containerDetailC")}>
         <span className={cx("heading-title")}>Property Overview</span>
         <div className={cx("addressDetailC")}>
            <PViAssetPoint label="One line Adress" assetName={<span>{p?.address_one_line || "N/A"}</span>} />
            <PViAssetPoint label="City/town" assetName={<span>{p?.city || "N/A"}</span>} />
            <PViAssetPoint label="State" assetName={<span>{p?.state || "N/A"}</span>} />
         </div>

         <div className={cx("assetDetailC")}>
            <SoSDataPoint
               affix="sqft"
               title="Square Feet"
               type="bold-title"
               value={p.sqft}
               icon="sqft"
               database={db_P("sqft")}
            />

            <SoSDataPoint
               prefix="Built in"
               title="Year Built"
               type="bold-title"
               value={p.year_built}
               icon="yearbuilt"
               database={db_P("year_built")}
            />

            <SoSDataPoint
               title="Property Type"
               type="bold-title"
               value={p.property_type}
               icon="propertyType"
               options={options_props_propertyTypes}
               database={db_P("property_type")}
            />
         </div>
         <div className={cx("assetDetailC")}>
            <SoSDataPoint title="Neighborhood" type="bold-title" value={p.neighborhood_name} icon="neighborhood" />

            <PViAssetPoint
               label="Days on Market"
               assetName={<span>6 DOM</span>}
               icon={<img src={CF_CDN_URL("/assets/property_validator/watch_later_black.svg")} alt="" />}
               isDefault
            />
            <SoSDataPoint
               title="Source"
               type="bold-title"
               value={p.source.source}
               icon="source"
               options={options_sources_source}
               database={db_S("source")}
            />
         </div>
      </div>
   );
};

const Characteristics = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};

   if (!p) return null;

   const db_P = dbcb("sos_properties", [{ column: "pid", id: p.pid }], () => qPVi_Property.refetch());

   return (
      <div className={cx("containerDetailC")}>
         <span className={cx("heading-title")}>Characteristics</span>
         <div className={cx("characteristicsDetailC")}>
            <SoSDataPoint
               title="Water Supply"
               type="bold-title"
               value={p.characteristics_water_supply || "N/A"}
               database={db_P("characteristics_water_supply")}
            />
            <SoSDataPoint
               title="Sewage Type"
               type="bold-title"
               value={p.characteristics_sewage_type || "N/A"}
               database={db_P("characteristics_sewage_type")}
            />
            <SoSDataPoint
               title="Drain Type"
               type="bold-title"
               value={p.characteristics_drain_type || "N/A"}
               database={db_P("characteristics_drain_type")}
            />
            <SoSDataPoint
               title="Construction"
               type="bold-title"
               value={p.characteristics_construction || "N/A"}
               database={db_P("characteristics_construction")}
            />
            <SoSDataPoint
               title="Foundation"
               type="bold-title"
               value={p.characteristics_foundation || "N/A"}
               database={db_P("characteristics_foundation")}
            />
            <SoSDataPoint
               title="Siding"
               type="bold-title"
               value={p.characteristics_siding || "N/A"}
               database={db_P("characteristics_siding")}
            />
            <SoSDataPoint
               title="Roof Type"
               type="bold-title"
               value={p.characteristics_roof_type || "N/A"}
               database={db_P("characteristics_roof_type")}
            />
            <SoSDataPoint
               title="Roof Age"
               type="bold-title"
               value={p.characteristics_roof_age || "N/A"}
               database={db_P("characteristics_roof_age")}
            />
            <SoSDataPoint
               title="Stories"
               type="bold-title"
               value={p.characteristics_stories || "N/A"}
               database={db_P("characteristics_stories")}
            />
         </div>
      </div>
   );
};

const FeaturesAndAmenities = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p } = qPVi_Property.data || {};

   if (!p) return null;

   const db_P = dbcb("sos_properties", [{ column: "pid", id: p.pid }], () => qPVi_Property.refetch());
   return (
      <div className={cx("containerDetailC")}>
         <span className={cx("heading-title")}>Features and Amenities</span>
         <div className={cx("featureDetailC")}>
            <SoSDataPoint
               title="Has Attic"
               type="bold-title"
               value={p.feature_has_attic}
               database={db_P("feature_has_attic")}
            />
            <SoSDataPoint
               title="Has Deck"
               type="bold-title"
               value={p.feature_has_deck}
               database={db_P("feature_has_deck")}
            />
            <SoSDataPoint
               title="Has Patio"
               type="bold-title"
               value={p.feature_has_patio}
               database={db_P("feature_has_patio")}
            />
            <SoSDataPoint
               title="Has Common Space"
               type="bold-title"
               value={p.feature_has_common_space}
               database={db_P("feature_has_common_space")}
            />
            <SoSDataPoint
               title="Has Yard"
               type="bold-title"
               value={p.feature_has_yard}
               database={db_P("feature_has_yard")}
            />
            <SoSDataPoint
               title="Has Elevator"
               type="bold-title"
               value={p.feature_has_elevator}
               database={db_P("feature_has_elevator")}
            />
         </div>
         <div className={cx("featureDetailC")}>
            <SoSDataPoint
               title="Has Basement"
               type="bold-title"
               value={p.feature_has_basement}
               database={db_P("feature_has_basement")}
            />
            <SoSDataPoint
               title="Has Common Laundry"
               type="bold-title"
               value={p.feature_has_common_laundry}
               database={db_P("feature_has_common_laundry")}
            />
            <SoSDataPoint
               title="Has Garage"
               type="bold-title"
               value={p.feature_has_garage}
               database={db_P("feature_has_garage")}
            />
            <SoSDataPoint
               title="Has Gate"
               type="bold-title"
               value={p.feature_has_gate}
               database={db_P("feature_has_gate")}
            />
            <SoSDataPoint
               title="Has Pool"
               type="bold-title"
               value={p.feature_has_pool}
               database={db_P("feature_has_pool")}
            />
            <SoSDataPoint
               title="Has Porch"
               type="bold-title"
               value={p.feature_has_porch}
               database={db_P("feature_has_porch")}
            />
         </div>
         <div className={cx("featureDetailC")}>
            <SoSDataPoint
               title="Has Sidewalk"
               type="bold-title"
               value={p.feature_has_sidewalk}
               database={db_P("feature_has_sidewalk")}
            />
            <SoSDataPoint
               title="Has Driveway"
               type="bold-title"
               value={p.feature_has_driveway}
               database={db_P("feature_has_driveway")}
            />
            <SoSDataPoint
               title="Has Exterior Stairs"
               type="bold-title"
               value={p.feature_has_exterior_stairs}
               database={db_P("feature_has_exterior_stairs")}
            />
         </div>
      </div>
   );
};
