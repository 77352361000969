import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useQPVi_PropertyStatuses } from "../queries/useQPVi_PropertyStatuses";
import { useQPVi_Properties } from "../queries/useQPVi_Properties";
import { useQPVi_Property } from "../queries/useQPVi_Property";
import { useMPVi_QuickPickProperty } from "./useMPVi_QuickPickProperty";
import { usePViContext } from "../../../contexts/PViContext";

export const useMPVi_UpdateProperty = () => {
   const { sosAxios } = useSosAxios();

   const { pviState } = usePViContext();

   const { pid } = pviState;

   const qPVi_PropertyStatuses = useQPVi_PropertyStatuses();

   const qPVi_Property = useQPVi_Property();

   const qPVi_QuickPickProperty = useMPVi_QuickPickProperty();

   const qPVi_Properties = useQPVi_Properties();

   const mutationKey = ["useMPVi_UpdateSourceStatus"];

   const isMutating = useIsMutating({ mutationKey });

   const mutation = useMutation({
      mutationKey,
      mutationFn: ({ status, reasons, rejectDetail, getNextQuickPick }: UpdateSourceData) =>
         sosAxios
            .patch(`/property-viability/properties/${pid}`, { status, reasons, rejectDetail })
            .then(({ data }: { data: UpdateSourceResponse }) => {
               if (getNextQuickPick) qPVi_QuickPickProperty.mutate();
               else qPVi_Property.refetch();
               qPVi_Properties.refetch();
               qPVi_PropertyStatuses.refetch();
               return data;
            }),
   });
   return { ...mutation, isMutating };
};

type UpdateSourceResponse = { message: string };

type UpdateSourceData = { status: string; reasons?: string[]; rejectDetail?: string; getNextQuickPick: boolean };
