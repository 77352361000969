import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useOffersContext } from "../../../../contexts/OffersContext";

export const useQOffers_GetLeadSourceProperties = ({ sid }: TSoS_Offers_GetLeadSourcePropertiesProps) => {
   const { sosAxios } = useSosAxios();
   const { offersState } = useOffersContext();
   const { lid } = offersState;

   const query = useQuery({
      queryKey: [`useQOffers_GetLeadSourceProperties`, lid, sid],
      staleTime: 500,
      queryFn: () =>
         sosAxios
            .get(`/offers/leads/${lid}/sources/${sid}/properties`)
            .then<TSoS_Offers_GetLeadSourceProperties_Response>(({ data }) => {
               return data;
            }),
   });
   return query;
};

export type TSoS_Offers_GetLeadSourcePropertiesProps = {
   sid: number;
};

export type TSoS_Offers_Contact = {
   cid: number;
   name: string;
   phone_primary: string;
   email: string;
};

export type TSoS_Offers_GetLeadSourceProperties_Property = {
   pid: string;
   address_one_line: string;
   asking_price: number;
   street_view_url_150: string;
   target_purchase_price_final: number;
   sqft: number;
   units: { uid: number; beds: number; baths: number }[];
   source_source?: string;
   contacts?: TSoS_Offers_Contact[];
};

export type TSoS_Offers_GetLeadSourceProperties_Response = {
   properties: TSoS_Offers_GetLeadSourceProperties_Property[];
};
