import classNames from "classnames/bind";
import styles from "./OffersLeadOverview.module.scss";
import { SoSDataPoint } from "../../../../components/SoSDataPoint/SoSDataPoint";
import {
   SoS_GetOptions_Option,
   useSoSOptions_Offers_Lead_Next_Actions,
} from "../../../../hooks/queries/options/useQSoS_Options";
import { useQOffers_Lead } from "../../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../hooks/database/mutations/utils";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useQOffers_GetLeads } from "../../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { formatDate } from "../../OffersLeadCard/OffersLeadCard";
import { useQ_Users } from "../../../../hooks/queries/user/useQ_Users";

const cx = classNames.bind(styles);

export const OffersLeadOverview = () => {
   const { offersState } = useOffersContext();
   const { lid } = offersState;

   const qOffersLead = useQOffers_Lead();
   const qOffersLeads = useQOffers_GetLeads();
   const qOffersLeadNextActions = useSoSOptions_Offers_Lead_Next_Actions();
   const qUsers = useQ_Users();

   const { options: leadNextActionOptions } = qOffersLeadNextActions.data || {};

   const userOptions = qUsers.data?.users.map((u) => ({ label: u.name, value: u.uid })) || [];

   if (!qOffersLead.data?.lead) return null;
   const lead = qOffersLead.data.lead;

   const db = dbcb("sos_leads", [{ column: "lid", id: lid }], () => {
      qOffersLead.refetch();
      qOffersLeads.refetch();
   });
   return (
      <div className={cx("container")}>
         <SoSDataPoint
            title="Total Max Price"
            type="bold-value"
            format="currency"
            value={lead.total_max_price}
            isHistory
         />
         <SoSDataPoint title="Target Offer Amount" type="bold-value" format="currency" isMocked isHistory value={100} />
         <SoSDataPoint
            title="Cap Rate (Cumulative)"
            type="bold-value"
            format="percentage"
            isHistory
            value={lead.cap_rate_cumulative}
         />
         <SoSDataPoint
            title="ARV Uplift (Cumulative)"
            type="bold-value"
            format="percentage"
            value={lead.arv_uplift_cumulative}
            isHistory
         />
         <SoSDataPoint title="Total ARV" type="bold-value" format="currency" value={lead.total_arv} isHistory />
         <SoSDataPoint title="Total NOI" type="bold-value" format="currency" value={lead.total_noi} isHistory />
         <SoSDataPoint title="Total All In" type="bold-value" format="currency" value={lead.total_all_in} isHistory />
         <SoSDataPoint title="Created Date" type="bold-title" value={formatDate(lead.created_at)} isAdd />
         <SoSDataPoint
            title="Lead Assignee"
            type="bold-title"
            value={userOptions
               .filter((u) => lead.assignee.includes(u.value))
               .map((u) => u.label)
               .join(", ")}
            // database={db("next_actions")}
            // isAdd
            // options={userOptions}
            // options_isMulti
         />
         <SoSDataPoint
            title="Lead Next Actions"
            type="bold-title"
            options_isMulti
            value={lead.next_actions}
            isAdd
            options={leadNextActionOptions}
            database={db("next_actions")}
         />
      </div>
   );
};
