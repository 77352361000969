import { useState, useEffect, useMemo } from "react";
import { SoSDataPoint_Params } from "../../../components/SoSDataPoint/SoSDataPoint";
import { useMDatabase_UpdateValue } from "../../database/mutations/useMDatabase_UpdateValue";
import { useDebounceCallback } from "../../react/useDebounceCallback";
import { useQPVi_Property } from "../queries/useQPVi_Property";
import { database_helpers_createPayloadHelper as db } from "../../database/mutations/utils";

type check = {
   pass: boolean;
   description: string;
};
type rule = {
   id: string;
   title: string;
   checks: check[];
   verified: boolean;
   verify: () => void;
   datapoint: Pick<SoSDataPoint_Params, "value" | "database" | "format">;
};
export const usePViDataVerification = () => {
   const qPVi_Property = useQPVi_Property();

   const { property: p, units } = qPVi_Property.data || {};

   const [verification, setVerification] = useState<Record<string, string | undefined>>(p?.data_verification || {});

   useEffect(() => setVerification(p?.data_verification || {}), [p?.data_verification]);

   const pid_id = { column: "pid", id: p?.pid || "" };

   const sid_id = { column: "sid", id: p?.source.sid || "" };

   const db_P = db("sos_properties", [pid_id], qPVi_Property.refetch);

   const db_S_P = db("sos_rel_sources_properties", [pid_id, sid_id], qPVi_Property.refetch);

   const mDb_P_dataVerification = useMDatabase_UpdateValue(db_P("data_verification"));

   const { debouncedCallback: dbUpdateDataV } = useDebounceCallback((newV: typeof verification) =>
      mDb_P_dataVerification.mutate({ value: JSON.stringify(newV) })
   );

   const rules = useMemo(() => {
      const SetVerification = ({ id, value }: { id: string; value: string | number | null | undefined }) => {
         const newV = { ...verification, [id]: verification[id] === value?.toString() ? undefined : value?.toString() };
         setVerification(newV);
         dbUpdateDataV(newV);
         qPVi_Property.refetch();
      };

      const rules: rule[] = [
         {
            id: "asking_price",
            title: "Asking Price",
            checks: [
               {
                  pass: !!p?.asking_price && p?.asking_price > 1000,
                  description: "Asking price should be greater than $1000",
               },
               {
                  pass: !!p?.asking_price,
                  description: "Asking price should be provided",
               },
               {
                  pass: !!p?.asking_price && p?.asking_price < 100000,
                  description: "Asking price should be less than $100,000",
               },
            ],
            verified: verification["asking_price"] === p?.asking_price?.toString(),
            verify: () => SetVerification({ id: "asking_price", value: p?.asking_price }),
            datapoint: { value: p?.asking_price, database: db_S_P("price"), format: "currency" },
         },
         {
            id: "sqft",
            title: "Square Footage",
            checks: [
               {
                  pass: !!p?.sqft,
                  description: "Square footage should be provided",
               },
               {
                  pass: !!p?.sqft && p?.sqft > 800,
                  description: "Sqft must be greater than 800",
               },
            ],
            verified: verification["sqft"] === p?.sqft?.toString(),
            verify: () => SetVerification({ id: "sqft", value: p?.sqft }),
            datapoint: { value: p?.sqft, database: db_P("sqft") },
         },
         ...(units?.flatMap((unit, index) => ({
            id: `unit_${index + 1}_bed`,
            title: `Unit #${index + 1}: Beds`,
            checks: [
               {
                  pass: !!unit?.bed,
                  description: "Bed should be provided",
               },
               {
                  pass: !!unit?.bed && unit?.bed > 0,
                  description: "Bed must be greater than 0",
               },
            ],
            verified: verification[`unit_${index + 1}_bed`] === unit?.bed?.toString(),
            verify: () => SetVerification({ id: `unit_${index + 1}_bed`, value: unit?.bed }),
            datapoint: {
               value: unit?.bed,
               database: db("sos_units", [{ column: "uid", id: unit?.uid }], qPVi_Property.refetch)("bed"),
            },
         })) || []),
      ];
      return rules;
   }, [dbUpdateDataV, db_P, db_S_P, p?.asking_price, p?.sqft, qPVi_Property, units, verification]);
   const isAllVerified = useMemo(() => rules.every((r) => r.verified), [rules]);
   return {
      rules,
      isAllVerified,
      isUpdatingVerification: mDb_P_dataVerification.isMutating,
   };
};
